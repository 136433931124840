import DraggableScrollArea from "@/components/DraggableScrollArea";
import useCurrentUser from "@/hooks/useCurrentUser";
import cloudflareLoader from "@/lib/cloudflare-images";
import BackgroundNeon from "@/src/components/BackgroundNeon";
import MainHeading from "@/src/components/MainHeading";
import Button from "@components/Button";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import Image from "next/legacy/image";

const AboutMobPremiumBlock = ({
  blockData,
  rounded = false,
  mini = false,
  wrapperClass = "",
}) => {
  const { currentUser } = useCurrentUser();
  const plan = currentUser?.plan ?? null;

  const planMapping = {
    null: "guest",
    2: "premiumMember",
    3: "freeMember",
    4: "freeMember",
  };

  const userType = planMapping[plan];

  const data = blockData.find(
    (block) => block.memberGroup === userType && block.enabledForGroup
  );

  if (!data) {
    return null;
  }

  const { featureShowcase, heading, intro, pricingText } = data;

  return (
    <div className={clsx("@container", wrapperClass)}>
      <div
        className={clsx("relative text-white", {
          "overflow-hidden md:rounded-2xl": rounded && !mini,
          "overflow-hidden lg:rounded-2xl": rounded && mini,
        })}
      >
        <BackgroundNeon />
        <div className="relative space-y-5 pb-8 pt-8 @3xl:space-y-6 @3xl:pt-10 @7xl:space-y-12 @7xl:pb-12 @7xl:pt-12">
          <div
            className={clsx(
              "flex flex-col justify-between gap-0.5 px-6 @3xl:gap-2 @3xl:pt-0 @5xl:flex-row @5xl:px-12 @7xl:gap-12",
              {
                "text-center @3xl:text-left": !mini,
                "text-center @3xl:text-left lg:text-left": mini,
              }
            )}
          >
            <div className="space-y-4 @5xl:max-w-[540px]">
              <div>
                <div className={clsx("hidden @3xl:block")}>
                  <MainHeading headingType="h2">{heading}</MainHeading>
                </div>
                <div className={clsx("block @3xl:hidden")}>
                  <h2 className={clsx("font-spatial text-3xl font-extrabold")}>
                    {heading}
                  </h2>
                </div>
              </div>
              <div
                className={clsx("body-text hidden font-body lg:block")}
                dangerouslySetInnerHTML={{ __html: intro }}
              />
            </div>
            <div className={clsx("flex flex-col space-y-6 @3xl:space-y-4")}>
              <div
                className={clsx("body-text")}
                dangerouslySetInnerHTML={{ __html: pricingText }}
              ></div>
              <div
                className={clsx("", {
                  hidden: mini,
                  "hidden justify-end space-x-2 @5xl:flex": !mini,
                })}
              >
                <_Buttons {...data} />
              </div>
            </div>
          </div>

          <div
            className={clsx("pb-2 @3xl:pb-0", {
              "block lg:hidden": mini,
            })}
          >
            <_BlockCarousel blocks={featureShowcase} />
          </div>

          <div
            className={clsx(" ", {
              "flex space-x-2 px-6 @lg:space-x-4": mini,
              "flex space-x-4 px-6 @5xl:hidden": !mini,
            })}
          >
            <_Buttons {...data} />
          </div>
        </div>
      </div>
    </div>
  );
};

const _Buttons = ({
  signUpButtonText,
  signUpButtonLink,
  learnMoreButtonText,
  learnMoreButtonLink,
}) => {
  return (
    <>
      <Button
        label={signUpButtonText}
        theme="light"
        href={signUpButtonLink}
        isSmall={false}
        isFull
      />
      <Button
        label={learnMoreButtonText}
        theme="dark"
        withBorder={false}
        href={learnMoreButtonLink}
        isSmall={false}
        isFull
      />
    </>
  );
};

const _BlockCarousel = ({ blocks }) => {
  return (
    <DraggableScrollArea>
      <div className={clsx("flex space-x-6 px-6 lg:px-12")}>
        {blocks.map((block) => {
          const image = block.image?.[0];

          return (
            <div
              key={block.key}
              className={clsx("w-72 snap-center snap-always")}
            >
              <div>
                <div
                  className={clsx(
                    "relative isolate aspect-[286/257] overflow-hidden rounded-2xl bg-white/20 backdrop-blur-sm"
                  )}
                >
                  {!!image && (
                    <Image
                      src={image.url}
                      layout="fill"
                      width={image.width}
                      objectFit="cover"
                      objectPosition="center"
                      alt={image.title}
                      draggable={false}
                      loader={cloudflareLoader}
                    />
                  )}
                </div>
                <div className={clsx("flex items-start space-x-2 pt-4")}>
                  <CheckCircleIcon className="h-6 w-6 shrink-0" />
                  <div className={clsx("body-text-sm")}>{block.heading}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DraggableScrollArea>
  );
};

export default AboutMobPremiumBlock;
